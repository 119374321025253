import React from 'react';
import './App.css';
import {PublicRouter} from "./routers/PublicRouter";

function App() {
    return (
        <>
            <PublicRouter/>
        </>
    );
}

export default App;
