import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import {ApplicationPage} from "../pages/public/application/ApplicationPage";

export const PUBLIC_ROUTES = {
    HOME: "/"
};

export const PublicRouter = () => (
    <BrowserRouter>
        <Routes>
            <Route path={PUBLIC_ROUTES.HOME} element={<ApplicationPage/>}/>
        </Routes>
    </BrowserRouter>
);
