import {API} from "./API";

export interface AttendeeRequest {

}

export interface CaretakerRequest {

}

export interface ApplicationRequest {
    attendee: AttendeeRequest;
    caretaker1: CaretakerRequest;
    caretaker2?: CaretakerRequest;
}

export const ApplicationAPI = {
    registerApplication(request: any): Promise<void> {
        return API.post<void>("/application", request);
    }
}
